@font-face {
    font-family: 'din_alternatebold';
    src: url('../../fonts/din_alternate_bold-webfont.woff2') format('woff2'),
         url('../../fonts/din_alternate_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

nav { height: 140px; }

nav ul { list-style: none; }

nav ul li {
  z-index: 1000;
  font-size: (@font-size-base+1);
}

nav ul li a {
  font-family: 'din_alternatebold', sans-serif;
  color: @brand-primary;
  text-decoration: none;
}

nav ul ul {
  display: none;
  width: 100%;
  min-height: 40px;
}

nav ul ul li {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: @font-size-base;
}

.nav-trigger {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  display: block;
}

label[for="nav-trigger"] {
  position: absolute;
  top: 69px;
  right: 23px;
  float: left;
  font-size: 35px;
  height: 80px;
  width: 80px;
  display: block;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  color: @gray-dark; }

label[for="nav-trigger"] i {
  margin-top: 28px;
  margin-left: 26px;
}
#mobile-container { display: none; }

.nav-trigger:checked ~ #mobile-container { display: block; }
  
@media (max-width: @screen-md-min) {
  #mobile-container {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 230px;
    float:initial !important;
    width: auto;
  }
  .search-form {
    width: 40%;
  }
  nav ul li ul {
    display: block;
  }
}

@media (min-width: @screen-sm-min) { 
  label[for="nav-trigger"] {
    position: relative;
  }
  #mobile-container {
    margin-top: 160px;
  }
}

@media (min-width: @screen-md-min) {

  .nav-trigger, label[for="nav-trigger"] {
    display: none;
  }

  #mobile-container {
    display: block;
    margin-top: 0;
  }

  nav a:hover { text-decoration: none; }

  nav ul {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding: 0;
  }

  nav ul li {
    width: 12.5%;
    float: left;
    padding-left: 0.75%;
    padding-right: 0.75%;
    text-align: center;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  nav ul ul li {
    display: block;
    min-height: 20px;
    height: auto;
    width: 100%;
    float: left;
    padding-top: auto;
    padding-bottom: auto;
  }

  nav ul li ul {
    display: none;
    background-color: @brand-primary;
    border-top: 1px solid #fff;
  }

  nav ul li.menu-item-has-children:hover {
    background-color: @brand-primary;
  }

  nav ul li.menu-item-has-children:hover a {
    color: #fff;
  }

  nav ul li.menu-item-has-children:hover > ul {
    display: block;
  }

  .search-form {
    margin-top: 35px;
    margin-bottom: 40px;
  }
}