/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Reset and dependencies
@import "normalize.less";
@import "print.less";
@import "glyphicons.less";

// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "code.less";
@import "grid.less";
@import "tables.less";
@import "forms.less";
@import "buttons.less";

// Components
@import "component-animations.less";
@import "dropdowns.less";
@import "button-groups.less";
@import "input-groups.less";
@import "navs.less";
@import "navbar.less";
@import "breadcrumbs.less";
@import "pagination.less";
@import "pager.less";
@import "labels.less";
@import "badges.less";
@import "jumbotron.less";
@import "thumbnails.less";
@import "alerts.less";
@import "progress-bars.less";
@import "media.less";
@import "list-group.less";
@import "panels.less";
@import "responsive-embed.less";
@import "wells.less";
@import "close.less";

// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";

// Custom classes
@import "custom-menu.less";


@light-orange: #fdb934;
@light-blue: #7bb6db;
@dark-blue: #1968b3;
@light-brown: #ce8c4e;
@dark-brown: #8d4b37;
@light-green: #bfd72f;
@dark-green: #59a446;

@brand-primary: @dark-blue;

@font-family-sans-serif:  Arial, sans-serif;
@font-size-base:          15px; 

@font-size-h1:            floor((@font-size-base * 2.4)); // ~36px
@font-size-h2:            floor((@font-size-base * 1.95)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.5)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.05)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@line-height-base:        1.6; // 20/14
@headings-line-height:    1.2;
@headings-color:          @brand-primary;

@btn-primary-bg:                 @light-blue;
@btn-primary-border:             @light-blue;

a {
    color: @gray-dark;
    text-decoration: underline;
}

a:hover { color: @dark-brown; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: @font-size-base;
  margin-bottom: @font-size-base;

  small,
  .small {
    font-size: 65%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: @font-size-base;
  margin-bottom: @font-size-base;

  small,
  .small {
    font-size: 75%;
  }
}

img { .img-responsive(); }

header {
    border-bottom: @brand-primary 1px solid;
    padding-bottom: 20px;
}

header img {
    max-width: 200px;
    max-height: 180px;
}

.footer-hills {
    margin-top: 20px;
    height: 80px;
    background-image: url(../../img/footer.svg);
    background-repeat: repeat-x;
}

footer {
    padding-top: 20px;
    background-color: @dark-green;
    min-height: 200px;
    color: #fff;
}

footer ul {
    list-style: none;
}

footer a {
    text-decoration: none;
    color: #fff;
}

footer a:hover {
  color: #111;
}

.action {
  background: @table-bg-accent;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 4%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 200px;
}

.action .btn {
  width: 100%;
  margin-top: 4%;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}

.btn-secondary {
  .button-variant(@btn-primary-color; @light-brown; @light-brown);
}

ul {
  list-style: url('../../img/leaf.svg');
}

img.alignright {
     float: right;
}
img.alignleft {
    margin: 0 2% 2% 0; 
    float: left;
 }

.front-left, .front-right {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 300px;
    padding: 10px;
    margin-top: 25px
}
.front-left { background: @light-green; }
.front-right { background: @light-blue; }

.widget_media_image { margin-top: 30px; }